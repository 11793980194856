
import { Component, Vue } from "vue-property-decorator";
import * as BlogService from '@/services/BlogServices';
import { mixins } from "vue-class-component";
import { TipTap } from "@/mixins/TipTap";
import { ROOT_ACTIONS } from "@/store/actions";

@Component({
})
export default class EditContent extends mixins(TipTap) {
    blog: any = null;
    sections = [] as any[];

    loading = false;

    loadingNewSection = '';

    updateKeys = {

    } as Record<string, number>;

    quoteImages = [
        {
            text: 'Groene Check',
            value: 'green-check'
        },
        {
            text: 'Rood Kruis',
            value: 'red-cross'
        }
    ]

    typeMappings = {
        'paragraph': 'Paragraaf',
        'banner': 'Banner',
        'image-text': 'Afbeelding + Tekst',
        'quote': 'Quote',
        'youtube': 'Youtube',
        'inline-images': 'Inline Afbeeldingen'
    } as Record<string, string>;

    async mounted() {
        const blogId = this.$route.params.id;
        this.blog = await BlogService.GetBlog(blogId);
        const sections = await BlogService.GetBlogSections(blogId);
        for (const section of sections) {
            this.updateKeys[section.id] = 0;
        }
        this.sections = sections;
    }

    async addSection(type: string) {
        this.loadingNewSection = type;
        let options = {}

        if (type === 'image-text') {
            options = {
                textPosition: 'left',
                value: '',
                credits: '',
            }
        }

        if (type === 'video-text') {
            options = {
                textPosition: 'left',
                value: '',
                credits: '',
            }
        }

        if (type === 'quote') {
            options = {
                showImage: false,
                image: 'green-check'
            }
        }

        if (type === 'youtube') {
            options = {
                value: '',
                width: 560,
                height: 315
            }
        }

        if (type === 'inline-images') {
            options = {
                count: 2,
                credits: [],
            }
        }

        const section = await BlogService.CreateBlogSection(this.blog.id, {
            type,
            options
        });
        this.updateKeys[section.id] = 0;
        this.sections.push(section);
        this.loadingNewSection = '';
    }

    async save() {
        this.loading = true;

        await BlogService.UpdateBlog(this.blog.id, this.blog);
        for (const section of this.sections) {
            await BlogService.SaveBlogSection(this.blog.id, section.id, section);
        }
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Content opgeslagen'
        })
        this.loading = false;
    }

    async saveLeadingImage() {
        const fileElement = document.getElementById('leading-image') as HTMLInputElement;
        if (!fileElement.files) return;
        const file = fileElement.files[0];
        await BlogService.SaveLeadingImage(this.blog.id, file);
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Afbeelding opgeslagen'
        })
    }

    async saveBannerImage(sectionId: string) {
        const fileElement = document.getElementById(`banner-image-${sectionId}`) as HTMLInputElement;
        if (!fileElement.files) return;
        const file = fileElement.files[0];
        await BlogService.SaveBannerImage(this.blog.id, sectionId, file);
        this.updateKeys[sectionId]++;
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Afbeelding opgeslagen'
        })
    }

    async saveInlineImage(sectionId: string, index: number) {
        const fileElement = document.getElementById(`inline-image-${sectionId}-${index}`) as HTMLInputElement;
        if (!fileElement.files) return;
        const file = fileElement.files[0];
        await BlogService.SaveInlineImage(this.blog.id, sectionId, index, file);
        this.updateKeys[sectionId]++;
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Afbeelding opgeslagen'
        })
    }

    async saveImageText(sectionId: string) {
        const fileElement = document.getElementById(`image-text-${sectionId}`) as HTMLInputElement;
        if (!fileElement.files) return;
        const file = fileElement.files[0];
        await BlogService.SaveImageText(this.blog.id, sectionId, file);
        this.updateKeys[sectionId]++;
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Afbeelding opgeslagen'
        })
    }

    async saveVideoText(sectionId: string) {
        const fileElement = document.getElementById(`video-text-${sectionId}`) as HTMLInputElement;
        if (!fileElement.files) return;
        const file = fileElement.files[0];
        await BlogService.SaveVideoText(this.blog.id, sectionId, file);
        this.updateKeys[sectionId]++;
        this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
            color: 'success',
            text: 'Video opgeslagen'
        })
    }

    async togglePublish() {
        this.blog.published = !this.blog.published;
        this.loading = true;
        await BlogService.UpdateBlog(this.blog.id, this.blog);
        this.loading = false;
    }

    async moveSection(index: number, direction: 'up' | 'down') {

        const section = this.sections[index];
        this.sections.splice(index, 1);
        this.sections.splice(direction === 'up' ? index - 1 : index + 1, 0, section);
        for (let i = 0; i < this.sections.length; i++) {
            this.sections[i].index = i;
        }

        for (const section of this.sections) {
            await BlogService.SaveBlogSection(this.blog.id, section.id, section);
        }
    }

    async deleteSection(sectionId: string) {
        this.sections = this.sections.filter(e => e.id !== sectionId);
        await BlogService.DeleteBlogSection(this.blog.id, sectionId);
        // Reindex
        for (let i = 0; i < this.sections.length; i++) {
            this.sections[i].index = i;
        }
        for (const section of this.sections) {
            await BlogService.SaveBlogSection(this.blog.id, section.id, section);
        }
    }

    async openPreview() {
        window.open(`https://decheckers.be/blog/${this.blog.id}`, '_blank');
    }
}
