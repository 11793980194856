import api from '@/plugins/axios';

import * as StorageServices from '@/services/StorageServices';

export const ListBlogs = async () => {
    const { data } = await api.get('/blogs')

    return data;
}

export const CreateBlog = async (blog: any) => {
    const { data } = await api.post('/blogs', blog);
    return data;
}

export const GetBlog = async (blogId: string) => {
    const { data } = await api.get(`/blogs/${blogId}`);
    return data;
}

export const GetBlogSections = async (blogId: string) => {
    const { data } = await api.get(`/blogs/${blogId}/sections`);
    return data;
}

export const CreateBlogSection = async (blogId: string, section: any) => {
    const { data } = await api.post(`/blogs/${blogId}/sections`, section);
    return data;
}

export const SaveBlogSection = async (blogId: string, sectionId: string, section: any) => {
    const { data } = await api.put(`/blogs/${blogId}/sections/${sectionId}`, section);
    return data;
}

export const SaveLeadingImage = async (blogId: string, image: File) => {
    console.log(image)
    await StorageServices.UploadPhoto(`blog-content/${blogId}/leading.png`, image);
}

export const SaveBannerImage = async (blogId: string, sectionId: string, image: File) => {
    await StorageServices.UploadPhoto(`blog-content/${blogId}/sections/${sectionId}/banner`, image);
}

export const SaveImageText = async (blogId: string, sectionId: string, image: File) => {
    await StorageServices.UploadPhoto(`blog-content/${blogId}/sections/${sectionId}/image-text`, image);
}

export const SaveVideoText = async (blogId: string, sectionId: string, video: File) => {
    await StorageServices.UploadPhoto(`blog-content/${blogId}/sections/${sectionId}/video-text.mp4`, video);
}

export const UpdateBlog = async (blogId: string, blog: any) => {
    const { data } = await api.patch(`/blogs/${blogId}`, blog);
    return data;
}

export const DeleteBlogSection = async (blogId: string, sectionId: string) => {
    await api.delete(`/blogs/${blogId}/sections/${sectionId}`);
}

export const SaveInlineImage = async (blogId: string, sectionId: string, index: number, image: File) => {
    await StorageServices.UploadPhoto(`/blog-content/${blogId}/sections/${sectionId}/inline-image-${index}`, image);
}