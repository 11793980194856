import Vue from "vue";
import Component from 'vue-class-component'
//@ts-ignore
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History} from 'tiptap-vuetify'


@Component({
  components: {
    TiptapVuetify
  }
})
export class TipTap extends Vue {
  extensions = [
    History,
    Blockquote,
    Link,
    Underline,
    Strike,
    Italic,
    ListItem,
    BulletList,
    OrderedList,
    [Heading, {
      options: {
        levels: [1, 2, 3]
      }
    }],
    Bold,
    Code,
    HorizontalRule,
    Paragraph,
    HardBreak,
  ]
}